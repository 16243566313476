import React from 'react';
import { Icon, InlineIcon } from '@iconify/react';
import menuIcon from '@iconify/icons-feather/menu';
import closeIcon from '@iconify/icons-zmdi/close';
import CollapseNavBar from './CollapseNavBar';
import { useMediaQuery } from 'react-responsive';

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isCollapseBarShow: false,
            isDesktopOrLaptop: true,
            isTabletOrMobile: true,
        };
        this.toggleNavbar = this.toggleNavbar.bind(this);

        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
        if (window.innerWidth <= 920) {
            this.setState(state => ({
                isDesktopOrLaptop: false
            }));
        }
    }
    toggleNavbar = () => {
        let navBar = document.getElementById('navbarSupportedContent');
        this.setState(state => ({
            isCollapseBarShow: !state.isCollapseBarShow
        }));
    }
    handleResize = () => {

        this.setState(state => ({
            isDesktopOrLaptop: !state.isDesktopOrLaptop
        }));

        //console.log(window.innerWidth);
    }

    render() {
        let className;
        if (this.state.isDesktopOrLaptop) {
            className = "";
        } else if (this.state.isCollapseBarShow) {
            className = "show"
        } else {
            className = "hide";
        }

        return <div class="menubar-area">
            <div class="container">
                <nav class="navbar navbar-expand-lg">
                    <div class="navbar-brand">
                        <a href="#">Mukesh Dhungana</a>
                        {!this.state.isCollapseBarShow ?
                            <Icon icon={menuIcon} id="showNavBarIcon" className="navbar-toggler-icon" onClick={this.toggleNavbar} />
                            : <Icon icon={closeIcon} id="closeNavBarIcon" className="navbar-toggler-icon" onClick={this.toggleNavbar} />}
                    </div>
                    <div className={className}>
                        <CollapseNavBar />
                        {/* {(this.state.isDesktopOrLaptop || this.state.isCollapseBarShow) &&
                            <CollapseNavBar />
                        } */}
                    </div>
                </nav>
            </div>
        </div>
    }
}

export default Header