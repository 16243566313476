import React from 'react';

class CollapseNavBar extends React.Component {
    render() {
        return <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item">
                    <a class="scrl-down nav-link" href="#home">Home</a>
                </li>
                <li class="nav-item">
                    <a class="scrl-down nav-link" href="#about">About me</a>
                </li>
                <li class="nav-item">
                    <a class="scrl-down nav-link" href="#skill">Skills</a>
                </li>
                <li class="nav-item">
                    <a class="scrl-down nav-link" href="#resume">Resume</a>
                </li>
                <li class="nav-item">
                    <a class="scrl-down nav-link" href="#testimonial">Testimonials</a>
                </li>
                <li class="nav-item">
                    <a class="scrl-down nav-link" href="#portfolio">Portfolio</a>
                </li>
                <li class="nav-item">
                    <a class="scrl-down nav-link" href="#contact">Contact us</a>
                </li>
            </ul>
        </div>
    }

}

export default CollapseNavBar
